<!-- Fab -->
<app-custom-fab *ngIf="!dialogMode && !minimal" [primaryFab]="primaryFab" [secondaryFab]="secondaryFab" (fabAction)="onFabAction($event)"></app-custom-fab>

<!-- TODO: simplify this file with templateOutlets so every column type can have any value with any template option, and then template can be set on the component -->
<!-- TODO: as we already have it with the templated field -->

<!-- Dialog close button -->
<div *ngIf="dialogMode" fxLayoutAlign="space-between" style="padding: 15px">
    <button mat-flat-button *ngIf="primaryFab" color="primary" (click)="onFabAction(primaryFab.id)" data-cy="primaryFab" tabindex="-1">
      <mat-icon>{{ primaryFab.icon }}</mat-icon> {{ primaryFab.i18nKey | translate }}
    </button>

<!--  this span is important for the space-between to cause the close icon to go to the end when there is nothing else -->
    <span></span>

    <button mat-button color="primary" (click)="dialogRef.close()" tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div fxLayout="column" fxLayoutAlign="space-between" [ngClass]="{'base-paddings': !dialogMode && !minimal}">

    <!-- Search and paginator-->
    <div fxLayout="column" class="py2">
      <!-- Search -->
      <div *ngIf="!minimal && searchEnabled" [ngClass]="{'x-paddings': dialogMode}">
        <mat-form-field class="" appearance="fill" fxFlex>
          <mat-label>{{ searchPlaceHolderi18NKey | translate }}</mat-label>
<!--          TODO: think if appAutofocus just be set only if we are not on phone / tablet, and only if we have a keyboard attached? otherwise, is it annoying? -->
          <input matInput #searchbox [appAutofocus]="autoFocusSearchBox" (focusout)="onSearchFocusOut()" (focusin)="onSearchFocusIn()" [value]="searchLiteral" type="search" data-cy="search">
        </mat-form-field>
      </div>

      <!-- Filters -->
      <ng-template #filtersTemplate>
        <div *ngFor="let fieldFilter of filters" class="ml2" [ngClass]="{'filter-active': !isFilterOnDefaultOption(fieldFilter)}">
          <mat-form-field appearance="fill">
            <mat-label>{{ filtersMetaData[fieldFilter].i18nKey | translate }}</mat-label>
            <select matNativeControl required (change)="onFilterChange(fieldFilter, $event.target.value)">
              <option *ngFor="let option of filtersMetaData[fieldFilter].options" [selected]="isFilterSelectSelected(fieldFilter, option)" value="{{ option.value }}">{{ option.literalI18NOpt | translate }}</option>
            </select>
          </mat-form-field>
        </div>
      </ng-template>

      <div *ngIf="!parentId && !minimal" fxLayout="row wrap" fxFlexAlign="end" fxHide.lt-md>
        <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
      </div>

      <mat-accordion *ngIf="!parentId && !minimal" fxHide fxShow.lt-md class="mb2">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "FILTERS" | translate }}
            </mat-panel-title>
            <mat-panel-description style="justify-content: end">
              <mat-icon>filter_alt</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div fxLayout="row wrap" fxLayoutAlign="center">
            <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>


      <!-- Paginator -->
      <div fxLayout="row" fxFill fxLayoutAlign="space-between" style="background-color: white;">
        <b fxFlexAlign="center" class="pl2" style="min-height: 56px; display: flex; align-items: center;">{{ "TOTAL" | translate }}: {{ paginationResultCount }}</b>
        <ng-container *ngIf="paginationResultCount > paginationPageSize">
          <mat-paginator [length]="paginationResultCount"
                         [pageSize]="paginationPageSize"
                         [pageSizeOptions]="paginationPageSizeOptions"
                         [pageIndex]="paginationPageCurrentPage-1"
                         (page)="pageEvent = $event">
          </mat-paginator>
        </ng-container>
      </div>

      <!-- Add item button -->
      <div *ngIf="addButtonI18N" fxLayout="row" fxFill fxLayoutAlign="end" class="pt1">
        <span>
          <button mat-stroked-button color="primary" [routerLink]="['/' + urlBaseDetailItem, 'create', {parentId: parentId}]">
            <mat-icon>add</mat-icon> {{ addButtonI18N | translate }}
          </button>
        </span>
      </div>

    </div>

    <div class="table-container">
      <table mat-table matSort multiTemplateDataRows fxFill [ngClass]="{'mat-elevation-z2': !dialogMode}" (matSortChange)="onSortChange($event)" [dataSource]="items">

          <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
        <ng-container *ngFor="let colDef of displayedColumns">

          <!-- Image types-->
          <ng-container *ngIf="isImageType(displayedColumnsMetaData[colDef].type)" matColumnDef="{{ colDef }}">
              <th mat-header-cell *matHeaderCellDef class="px2i"> {{ displayedColumnsMetaData[colDef].i18nKey | translate }} </th>
              <td mat-cell *matCellDef="let element" class="link-action px2i" (click)="onRowClick(element)">
                <!-- Profile picture -->
                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.profile_picture">
                  <app-image-profile [imgSrc]="element[colDef]" [name]="getElementStr(element)" w="35" h="35"></app-image-profile>
                </ng-container>
                <!-- Picture -->
                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.picture">
                  <app-image-kit-image [imgSrc]="element[colDef]" w="35" h="35"></app-image-kit-image>
                </ng-container>
              </td>
          </ng-container>

          <!-- Non images  types -->
          <ng-container *ngIf="displayedColumnsMetaData[colDef].type !== displayedColumnTypes.picture && displayedColumnsMetaData[colDef].type !== displayedColumnTypes.profile_picture" matColumnDef="{{ colDef }}">
              <th mat-header-cell mat-sort-header *matHeaderCellDef class="px2i" [ngClass]="{'contents_right': (isNumericType(displayedColumnsMetaData[colDef].type))}">{{ displayedColumnsMetaData[colDef].i18nKey | translate }}</th>
              <td mat-cell *matCellDef="let element" disabled class="link-action px2i" [ngClass]="{'contents_right text-overflow-clip': (isNumericType(displayedColumnsMetaData[colDef].type))}" (click)="onRowClick(element, colDef)">
                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.text">
                  {{ element[colDef] }}
                </ng-container>

                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.templated">
                  <ng-container
                    [ngTemplateOutlet]="getFieldTemplateRef(element, colDef) || TemplateDefaultColumn"
                    [ngTemplateOutletContext]="getFieldTemplatedContext(element, colDef)"></ng-container>
                </ng-container>

                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.simple_html">
                  <span [innerHTML]="getHtmlFieldContent(element, colDef)"></span>
                </ng-container>

                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.calculated">
                  <ng-container *ngIf="colDef.includes('price')">
                    {{ getCalculatedField(element, colDef) | currency: currencyCode : 'symbol-narrow' }}
                  </ng-container>
                  <ng-container *ngIf="!colDef.includes('price')">
                    <ng-container *ngIf="getInputTypeCalculated(colDef) == 'string'">
                      {{ getCalculatedField(element, colDef) | translate }}
                    </ng-container>
                    <ng-container *ngIf="getInputTypeCalculated(colDef) == 'image'">
                      <app-image-kit-image [imgSrc]="getCalculatedField(element, colDef)" w="35" h="35"></app-image-kit-image>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.currency">
                  <span [ngClass]="privacyModeService.isPrivacyModeEnabled() ? 'blur' : ''">{{ element[colDef] | currency: currencyCode : 'symbol-narrow' }}</span>
                </ng-container>
                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.date">
                  {{ element[colDef] | date: 'd MMM' }}
                </ng-container>
                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.dateAgo">
                  {{ element[colDef] | dateAgo }}
                </ng-container>
                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.date_time">
                  {{ element[colDef] | date: 'd MMM HH:mm' }}
                </ng-container>
                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.number">
                  {{ element[colDef] }}
                </ng-container>

                <ng-container *ngIf="displayedColumnsMetaData[colDef].type === displayedColumnTypes.anchor">
                  <a [routerLink]="getUrlItem(element)" class="link-action">
                    <span class="pr1">{{ element[colDef] }}</span></a>
                </ng-container>

              </td>
          </ng-container>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <!-- TODO: make generic so other lists can also use expanded detail, using expandedDetailText calculated field -->
            <a *ngIf="element['generalNotesPrivate']" [routerLink]="getUrlItem(element)" class="link-action">
              <div class="element-detail" [style]="{'height': (element['generalNotesPrivate'] ? 'auto' : '0px')}" [class]="{'pb2': element['generalNotesPrivate']}">
                <span class="mb-2">{{ getCalculatedField(element, 'expandedDetailText') }}</span>
              </div>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (contextmenu)="onRightClickRow($event, row)" [class]="getExtraRowClass(row)" class="element-row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [class]="getExtraRowClass(row)" class="detail-row"></tr>

  <!--      &lt;!&ndash; TODO: Row shown when there is no matching data. &ndash;&gt;-->
  <!--      <tr class="mat-row" *matNoDataRow>-->
  <!--        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
  <!--      </tr>-->
      </table>
    </div>

    <!-- Right click row menu -->
    <!-- an hidden div is created to set the position of appearance of the menu-->
    <div style="visibility: hidden; position: fixed;" [style.left]="menuTopLeftPosition.x"
         [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="rightClickMenuRow"></div>

    <!-- standard material menu -->
    <mat-menu #rightClickMenuRow="matMenu">
      <ng-template matMenuContent let-item="item">
        <button *ngFor="let action of itemContextMenuActions" mat-menu-item (click)="onContextMenuItemClicked(action.id)">{{ action.i18nKey | translate }}</button>
      </ng-template>
    </mat-menu>

</div>

<div *ngIf="loading" fxLayoutAlign="center" style="padding-top: 150px">
    <div fxLayout="column">
        <mat-spinner></mat-spinner>
        <span fxLayoutAlign="center" style="padding-top: 20px">{{ 'LOADING' | translate }} ...</span>
    </div>
</div>

<div *ngIf="errorLoading" fxLayoutAlign="center" style="padding-top: 150px" (click)="retryRefreshList()" class="link-action">
    <div fxLayout="column">
        <mat-icon fxFlexAlign="center" class="sync_problem_icon">sync_problem</mat-icon>
        <span fxFlexAlign="center" style="padding-top: 20px">{{ 'RETRY' | translate }}</span>
    </div>
</div>

<ng-template let-value="value" let-customClass="customClass" #TemplateDefaultColumn>
  <span class="{{ customClass }}">{{ value }}</span>
</ng-template>

<ng-template let-value="value" let-customClass="customClass" #TemplateI18N>
  <span class="{{ customClass }}">{{ value | translate }}</span>
</ng-template>

<ng-template let-icon="icon" let-color="color" let-tooltipI18N="tooltipI18N" let-customClass="customClass" #TemplateIcon>
  <mat-icon class="{{ customClass }}" [ngStyle]="{'color': color}" matTooltip="{{ tooltipI18N | translate }}">{{ icon }}</mat-icon>
</ng-template>
