import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {TitleService} from 'src/app/services/title.service';
import {environment} from 'src/environments/environment';
import {AbstractParentItem} from '../../models/abstract_parent_item';
import {BikeCRMApiAbstract} from '../../services/bikecrm-api-base';
import {
  ChildItemEditableListAbstractComponent
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {AbstractChildItem} from '../../models/abstract_child_item';
import {animate, query, state, style, transition, trigger} from '@angular/animations';
import {Fab, IFab} from '../fab-custom/fab-interface';
import {BillableItem} from '../../models/billable_item';
import {PaymentStatusCardComponent} from '../payment-status-card/payment-status-card.component';
import {UsersService} from '../../services/users.service';


/*
  * This class is used as a base for all the detail pages that you want also to show a list of
  *   items related to the parent item.
  *
  * TODO: rename class to something like abstract-master-detail, or abstract-detail-list
  * TODO: make sure there is nothing else specific on order/products (also on tempaltes)
 */

export interface IParentDetail<T> {
  parentItem: T;
  parentId?: string;
  navBase: string;

  titleI18N: string;
  titleIcon: string;

  // Sidebar actions:
  actions: ParentItemAction[];

  childItemListRef: ChildItemEditableListAbstractComponent<AbstractChildItem<any>>;
  childListHost: any;

  // TODO: move to a common app interface
  isFully: boolean;

  childListRefComponentRef: ComponentRef<any>;

  configureKeyboardShortcuts(): void;

  loadChildListComponent(): void;
}

export class ParentItemAction {
  constructor(i18nKey: string, icon: string, id: string, enabled = true) {
    this.i18nKey = i18nKey;
    this.icon = icon;
    this.id = id;
    this.enabled = enabled;
  }

  i18nKey: string;
  icon: string;
  id: string;
  enabled: boolean;
}


@Component({
  template: '',
  encapsulation: ViewEncapsulation.None,
  // TODO: Document
  animations: [
    trigger('spinInOut', [
      state('in', style({transform: 'rotate(0)', opacity: '1'})),
      transition(':enter', [
        style({transform: 'rotate(-180deg)', opacity: '0'}),
        animate('150ms ease')
      ]),
      transition(':leave', [
        animate('150ms ease', style({transform: 'rotate(180deg)', opacity: '0'}))
      ]),
    ]),
    trigger('preventInitialAnimation', [
      transition(':enter', [
        query(':enter', [], {optional: true})
      ]),
    ]),
  ],
})
export abstract class AbstractParentDetailComponent<T extends AbstractParentItem>
  implements IParentDetail<T>, IFab, OnInit, AfterViewInit, AfterContentChecked, OnDestroy {

  @ViewChild('paymentCard') paymentCard: PaymentStatusCardComponent;

  env = environment;

  parentItem$: Observable<T>;

  readyToAddChildItems = true;

  showSharePrintButtons = false;

  parentItem: T;

  abstract titleI18N: string;
  abstract titleIcon: string;

  abstract primaryFab: Fab;
  abstract secondaryFab: Fab;

  abstract actions: ParentItemAction[];

  parentId?: string;

  // FormData to send when creating a new parent item
  initialCreateFormData = new FormData();

  // tslint:disable-next-line:variable-name
  _childListLoaded = false;

  // TODO: use fully kiosk js apis, and move to a NativeInterfacesService or similar
  isFully = false;
  childListRefComponentRef: ComponentRef<any>;

  protected onDestroy$: Subject<void> = new Subject<void>();

  abstract navBase;
  abstract childItemListRef;
  abstract childListHost;

  currencyCode: string;

  onFabAction(actionId: string): void {
    console.log('If you have fab actions defined, you should override this method.');
  }

  // Sidebar actions:
  onAction(actionId: string): void {
    console.log('If you have actions defined, you should override this method.');
  }

  configureKeyboardShortcuts(): void {
  }

  abstract loadChildListComponent(): void;

  constructor(
    protected titleService: TitleService,
    protected translate: TranslateService,
    protected notificationService: CrmNotificationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected router: Router,
    protected dialog: MatDialog,
    protected progressDialog: MatDialog,
    protected route: ActivatedRoute,
    protected parentItemService: BikeCRMApiAbstract,
    protected usersService: UsersService,
  ) {
    this.isFully = nativeInterfacesService.inFully;
  }

  ngAfterContentChecked(): void {
    if (!this._childListLoaded && this.childListHost !== undefined && this.parentItem !== null) {
      this.loadChildListComponent();
      this._childListLoaded = true;
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.parentId = this.route.snapshot.paramMap.get('id');
    if (this.parentId) {
      this.parentItem$ = this.parentItemService.get(this.parentId);
      this.parentItem$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
        this.parentItem = x;
        this.onParentItemChange();
      });
    } else {
      this.createNewParentItem();
    }
    this.configureKeyboardShortcuts();
    this.currencyCode = this.usersService.business.currency.toUpperCase();
  }

  onParentItemChange(): void {
  }

  public ngOnDestroy(): void {
    if (this.childListRefComponentRef) {
      this.childListRefComponentRef.destroy();
    }
    this.onDestroy$.next();
  }

  createNewParentItem(): void {
    const formData = this.initialCreateFormData;

    this.parentItemService.create(formData).pipe(takeUntil(this.onDestroy$)).subscribe(
      x => this.router.navigate([`${this.navBase}${x.id}`], {replaceUrl: true})
    );
  }

  saveParentItem$(): Observable<T> {
    return this.parentItemService.modify(this.parentItem.id, this.parentItem).pipe(takeUntil(this.onDestroy$));
  }

  saveParentItem(): void {
    this.saveParentItem$().subscribe(
      x => {
        this.parentItem = x;
        this.onParentItemChange();
      }
    );
  }

  isParentBillableItem(): boolean {
    return BillableItem.isBillableItem(this.parentItem);
  }

  sendDocumentWhatsapp(document: string): void {
    throw new Error('Method sendDocumentWhatsapp not implemented.');
  }

}
