import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

interface Currency {
  code: string;
  symbol: string;
  denominations: number[];
}

@Component({
  selector: 'app-cash-change-calculator',
  templateUrl: './cash-change-calculator.html',
  styleUrls: ['./cash-change-calculator.scss']
})
export class CashChangeCalculatorComponent implements OnInit {
  totalAmount = 0;
  currencyCode = 'EUR';

  calculatorForm: FormGroup;
  change = 0;
  amountPaid = 0;
  changeBreakdown: { [key: number]: number } = {};
  paidBreakdown: { [key: number]: number } = {};

  currencies: Currency[] = [
    {
      code: 'EUR',
      symbol: '€',
      // denominations: [500, 200, 100, 50, 20, 10, 5, 2, 1, 0.5, 0.2, 0.1, 0.05, 0.02, 0.01]
      denominations: [100, 50, 20, 10, 5, 2, 1, 0.5, 0.2, 0.1, 0.05, 0.02, 0.01]
    },
    {
      code: 'USD',
      symbol: '$',
      denominations: [100, 50, 20, 10, 5, 2, 1, 0.5, 0.25, 0.1, 0.05, 0.01]
    }
    // Add more currencies as needed
  ];

  currentCurrency: Currency;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {totalAmount: string, currencyCode: string}) {
    this.calculatorForm = this.fb.group({});
    this.currentCurrency = this.currencies[0]; // Default to EUR

    this.totalAmount = parseFloat(data.totalAmount);
    this.currencyCode = data.currencyCode;
  }

  ngOnInit(): void {
    console.log('openCalculator', this.totalAmount);
    this.currentCurrency = this.currencies.find(c => c.code === this.currencyCode) || this.currencies[0];
    this.initForm();
  }

  initForm(): void {
    this.calculatorForm = this.fb.group({});
    this.currentCurrency.denominations.forEach(denomination => {
      this.calculatorForm.addControl(denomination.toString(), this.fb.control(0, [Validators.min(0)]));
    });
  }

  updatePaidAmount(denomination: number, increment: boolean): void {
    const control = this.calculatorForm.get(denomination.toString());
    if (control) {
      const currentValue = control.value || 0;
      control.setValue(increment ? currentValue + 1 : Math.max(0, currentValue - 1));
      this.calculateChange();
    }
  }

  calculateChange(): void {
    if (this.calculatorForm.valid) {
      this.amountPaid = 0;
      this.paidBreakdown = {};

      Object.keys(this.calculatorForm.controls).forEach(key => {
        const denomination = parseFloat(key);
        const count = this.calculatorForm.get(key)?.value || 0;
        if (count > 0) {
          this.paidBreakdown[denomination] = count;
          this.amountPaid += denomination * count;
        }
      });

      this.change = +(this.amountPaid - this.totalAmount).toFixed(2);
      this.breakdownChange();
    }
  }

  breakdownChange(): void {
    this.changeBreakdown = {};
    let remainingChange = this.change;

    for (const denomination of this.currentCurrency.denominations) {
      const count = Math.floor(remainingChange / denomination);
      if (count > 0) {
        this.changeBreakdown[denomination] = count;
        remainingChange = +(remainingChange - denomination * count).toFixed(2);
      }
    }
  }
}
