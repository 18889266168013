<mat-card>
  <mat-card-header>
    <mat-card-title>Cash Change Calculator ({{ currentCurrency.code }})</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <h3>Total Price: {{ totalAmount | currency:currentCurrency.code:'symbol' }}</h3>
    <h3>Amount Paid: {{ amountPaid | currency:currentCurrency.code:'symbol' }}</h3>
    <h3>Change Due: {{ change | currency:currentCurrency.code:'symbol' }}</h3>

    <mat-divider></mat-divider>

    <form [formGroup]="calculatorForm" class="grid-container">
      <div *ngFor="let denomination of currentCurrency.denominations" class="grid-item">
        <mat-form-field appearance="fill">
          <mat-label>{{ denomination | currency:currentCurrency.code:'symbol' }}</mat-label>
          <input matInput type="number" [formControlName]="denomination.toString()" readonly>
        </mat-form-field>
        <div class="button-group">
          <button mat-icon-button color="primary" (click)="updatePaidAmount(denomination, true)">
            <mat-icon>add</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="updatePaidAmount(denomination, false)">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="change > 0">
      <h4>Change Breakdown:</h4>
      <mat-list>
        <mat-list-item *ngFor="let item of changeBreakdown | keyvalue">
          {{ item.key | currency:currentCurrency.code:'symbol' }}: {{ item.value }}
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>
